<!-- eslint-disable -->
<template>
  <v-container v-once>
    <v-row>
      <v-col cols="12">
        <DIV id="page_1">
          <P class="p0 ft0">PRIVACY POLICY THE SCALABLE FACTORY B.V.</P>
          <P class="p1 ft2"
            >We are The Scalable Factory B.V.. We respect your privacy and
            private life, but sometimes we need your Personal Data. We consider
            <SPAN class="ft1">Personal Data </SPAN>to be any information
            relating to an identified or identifiable person, in conformity with
            the General Data Protection Regulation (the
            <SPAN class="ft1">GDPR</SPAN>).</P
          >
          <P class="p2 ft4"
            >This policy explains which Personal Data we use and why (the
            <SPAN class="ft3">Privacy Policy</SPAN>). Furthermore, you will read
            how we store, protect and process this Personal Data.</P
          >
          <P class="p3 ft6"
            >This Privacy Policy applies to our Website www.visionbook.com (the
            <SPAN class="ft5">Website</SPAN>), our online application (the
            <SPAN class="ft5">App</SPAN>) and the services or products we
            provide (the <SPAN class="ft5">Services</SPAN>). We process your
            Personal Data in accordance with the GDPR and all other relevant
            legislation and regulations in the field of protection of Personal
            Data, like Dutch Telecommunications Act (Telecommunicatiewet)
            regarding the use of cookies (the
            <SPAN class="ft5">Relevant Legislation</SPAN>).</P
          >
          <P class="p4 ft7">Are you under the age of 16?</P>
          <P class="p5 ft4"
            >If you are younger than 16 years old, you need permission from your
            parents or legal guardian to use our Website, App and Services.</P
          >
          <P class="p6 ft7">Processing of Personal Data</P>
          <P class="p7 ft8"
            >In order to provide you with our Website, App and Services, we
            process your Personal Data.</P
          >
          <P class="p8 ft7">Who is the controller of your Personal Data?</P>
          <P class="p9 ft4"
            >We are the controller of your Personal Data within the meaning of
            the Relevant Legislation. At the end of this Policy, you can find
            our contact details .</P
          >
          <P class="p10 ft7"
            >What Personal Data do we process, on which legal basis and for
            which specified purpose(s)</P
          >
          <P class="p7 ft8"
            >We need some of your Personal Data in order for you to use our
            Website, Apps and Services.</P
          >
          <P class="p11 ft4"
            >We are allowed to process your Personal Data, because we comply
            with the Relevant Legislation. We lawfully process your Personal
            Data because:</P
          >
          <P class="p14 ft12">
            <Icon x-small iconClass="pb-2" color="black">{{
              svgMovieOpen
            }}</Icon>
            <SPAN class="ft11"
              >Processing Personal Data is necessary in order to perform our
              contract;</SPAN
            ></P
          >
          <P class="p14 ft12"
            ><Icon x-small iconClass="pb-2" color="black">{{
              svgMovieOpen
            }}</Icon
            ><SPAN class="ft11"
              >We have to comply with a legal obligation;</SPAN
            ></P
          >
          <P class="p14 ft12"
            ><Icon x-small iconClass="pb-2" color="black">{{
              svgMovieOpen
            }}</Icon
            ><SPAN class="ft11"
              >We have legitimate interests to process your Personal Data, where
              we have considered these are not overridden by your rights;</SPAN
            ></P
          >
          <P class="p14 ft12"
            ><Icon x-small iconClass="pb-2" color="black">{{
              svgMovieOpen
            }}</Icon
            ><SPAN class="ft11">You have given us consent;</SPAN></P
          >
          <P class="p14 ft12"
            ><Icon x-small iconClass="pb-2" color="black">{{
              svgMovieOpen
            }}</Icon
            ><SPAN class="ft11"
              >Processing is necessary in order to protect the vital interests
              of you or another person, or</SPAN
            ></P
          >
          <P class="p14 ft12"
            ><Icon x-small iconClass="pb-2" color="black">{{
              svgMovieOpen
            }}</Icon
            ><SPAN class="ft11"
              >Processing is necessary for the performance of a task carried out
              in the public interest or in the exercise of official authority
              vested in us.</SPAN
            ></P
          >
          <P class="p18 ft4"
            >We make sure that the processing of your Personal Data is adequate,
            relevant and limited to what is necessary in relation to the purpose
            for which the Data is processed.</P
          >
          <P class="p19 ft4"
            >In the table below you will read (1) which Personal Data we process
            (2) for which purpose(s) and (3) on which legal basis.</P
          >
          <P class="p20 ft4"
            >We shall only use your Personal Data for the following purposes or
            for compatible purposes. By doing so, we will not use your Personal
            Data in an unexpected manner.</P
          >
          <div id="tabledesc" style="overflow-x:auto;">
            <TABLE aria-describedby="tabledesc" class="t0">
              <TR>
                <TH scope="col" class="tr0 td0"
                  ><P class="p21 ft7">(Personal) Data</P></TH
                >
                <TH scope="col" class="tr0 td1"
                  ><P class="p22 ft7">Purpose(s)</P></TH
                >
                <TH scope="col" class="tr0 td2"
                  ><P class="p23 ft7">Legal basis</P></TH
                >
              </TR>
              <TR>
                <TD class="tr1 td3"><P class="p24 ft15">&nbsp;</P></TD>
                <TD class="tr1 td4"><P class="p24 ft15">&nbsp;</P></TD>
                <TD class="tr1 td5"><P class="p24 ft15">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr2 td6"><P class="p21 ft16">Contact Data:</P></TD>
                <TD class="tr2 td7"
                  ><P class="p22 ft16">We use these Data:</P></TD
                >
                <TD class="tr2 td8"
                  ><P class="p23 ft16">We process these Data on</P></TD
                >
              </TR>
              <TR>
                <TD class="tr3 td6"><P class="p21 ft8">-Company name</P></TD>
                <TD class="tr3 td7"><P class="p22 ft8">-To contact you</P></TD>
                <TD class="tr3 td8"><P class="p23 ft16">the basis of:</P></TD>
              </TR>
              <TR>
                <TD class="tr3 td6"
                  ><P class="p21 ft8">-First and/or last name</P></TD
                >
                <TD class="tr3 td7"
                  ><P class="p22 ft8">-To correspond with you</P></TD
                >
                <TD class="tr3 td8"
                  ><P class="p23 ft8">-A necessity to perform</P></TD
                >
              </TR>
              <TR>
                <TD class="tr3 td6"><P class="p21 ft8">-Email address</P></TD>
                <TD class="tr3 td7"
                  ><P class="p22 ft8">-For the delivery or</P></TD
                >
                <TD class="tr3 td8"><P class="p23 ft8">the contract</P></TD>
              </TR>
              <TR>
                <TD class="tr3 td6"><P class="p21 ft8">-Address</P></TD>
                <TD class="tr3 td7"
                  ><P class="p22 ft8">performance of our Services</P></TD
                >
                <TD class="tr3 td8"><P class="p23 ft8">-Consent</P></TD>
              </TR>
              <TR>
                <TD class="tr3 td6"><P class="p21 ft8">-Phone number</P></TD>
                <TD class="tr3 td7"><P class="p23 ft8">to you</P></TD>
                <TD class="tr3 td8"><P class="p24 ft17">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr3 td6"><P class="p21 ft8">-IP-address</P></TD>
                <TD class="tr3 td7"><P class="p24 ft17">&nbsp;</P></TD>
                <TD class="tr3 td8"><P class="p24 ft17">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr3 td6"
                  ><P class="p21 ft8">-Chamber of Commerce</P></TD
                >
                <TD class="tr3 td7"><P class="p24 ft17">&nbsp;</P></TD>
                <TD class="tr3 td8"><P class="p24 ft17">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr3 td6"><P class="p21 ft8">number</P></TD>
                <TD class="tr3 td7"><P class="p24 ft17">&nbsp;</P></TD>
                <TD class="tr3 td8"><P class="p24 ft17">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr3 td6"><P class="p21 ft8">-VAT number </P></TD>
                <TD class="tr3 td7"><P class="p24 ft17">&nbsp;</P></TD>
                <TD class="tr3 td8"><P class="p24 ft17">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr1 td3"><P class="p24 ft15">&nbsp;</P></TD>
                <TD class="tr1 td4"><P class="p24 ft15">&nbsp;</P></TD>
                <TD class="tr1 td5"><P class="p24 ft15">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr2 td6"><P class="p22 ft16">Payment Data:</P></TD>
                <TD class="tr2 td7"
                  ><P class="p22 ft16">We use these Data:</P></TD
                >
                <TD class="tr2 td8"
                  ><P class="p23 ft8">-A necessity to perform</P></TD
                >
              </TR>
              <TR>
                <TD class="tr3 td6"
                  ><P class="p21 ft8">-Payment Data of the</P></TD
                >
                <TD class="tr3 td7"
                  ><P class="p22 ft8">-To send invoices</P></TD
                >
                <TD class="tr3 td8"><P class="p23 ft8">the contract</P></TD>
              </TR>
              <TR>
                <TD class="tr3 td6"><P class="p21 ft8">paying party</P></TD>
                <TD class="tr3 td7"
                  ><P class="p22 ft8">-To update our financial</P></TD
                >
                <TD class="tr3 td8"><P class="p23 ft8">-Consent</P></TD>
              </TR>
              <TR>
                <TD class="tr3 td6"><P class="p21 ft8">-Invoices</P></TD>
                <TD class="tr3 td7"><P class="p22 ft8">administration</P></TD>
                <TD class="tr3 td8"><P class="p24 ft17">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr3 td6"
                  ><P class="p21 ft18">-An overview of the purchases</P></TD
                >
                <TD class="tr3 td7"><P class="p24 ft17">&nbsp;</P></TD>
                <TD class="tr3 td8"><P class="p24 ft17">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr3 td6"><P class="p21 ft8">of our Services</P></TD>
                <TD class="tr3 td7"><P class="p24 ft17">&nbsp;</P></TD>
                <TD class="tr3 td8"><P class="p24 ft17">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr1 td3"><P class="p24 ft15">&nbsp;</P></TD>
                <TD class="tr1 td4"><P class="p24 ft15">&nbsp;</P></TD>
                <TD class="tr1 td5"><P class="p24 ft15">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr2 td6"
                  ><P class="p22 ft16">Data for marketing and</P></TD
                >
                <TD class="tr2 td7"
                  ><P class="p22 ft16">We use these Data:</P></TD
                >
                <TD class="tr2 td8"
                  ><P class="p23 ft8">-A necessity to perform</P></TD
                >
              </TR>
              <TR>
                <TD class="tr3 td6"
                  ><P class="p21 ft16">promotional reasons:</P></TD
                >
                <TD class="tr3 td7"
                  ><P class="p22 ft8">-For marketing and</P></TD
                >
                <TD class="tr3 td8"><P class="p23 ft8">the contract</P></TD>
              </TR>
              <TR>
                <TD class="tr3 td6"
                  ><P class="p21 ft8">-First and/or last name</P></TD
                >
                <TD class="tr3 td7"
                  ><P class="p22 ft8">promotional reasons</P></TD
                >
                <TD class="tr3 td8"><P class="p23 ft8">-Consent</P></TD>
              </TR>
              <TR>
                <TD class="tr3 td6"><P class="p21 ft8">-Email address</P></TD>
                <TD class="tr3 td7"
                  ><P class="p22 ft8">-In order to make you offers</P></TD
                >
                <TD class="tr3 td8"><P class="p24 ft17">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr3 td6"><P class="p21 ft8">-Address</P></TD>
                <TD class="tr3 td7"
                  ><P class="p23 ft8">-In order to send you our</P></TD
                >
                <TD class="tr3 td8"><P class="p24 ft17">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr3 td6"><P class="p24 ft17">&nbsp;</P></TD>
                <TD class="tr3 td7"
                  ><P class="p23 ft8">newsletter and/or updates</P></TD
                >
                <TD class="tr3 td8"><P class="p24 ft17">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr3 td6"><P class="p24 ft17">&nbsp;</P></TD>
                <TD class="tr3 td7"
                  ><P class="p23 ft8">(you can always unsubscribe</P></TD
                >
                <TD class="tr3 td8"><P class="p24 ft17">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr3 td6"><P class="p24 ft17">&nbsp;</P></TD>
                <TD class="tr3 td7"
                  ><P class="p23 ft8">from our newsletter and/or</P></TD
                >
                <TD class="tr3 td8"><P class="p24 ft17">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr3 td6"><P class="p24 ft17">&nbsp;</P></TD>
                <TD class="tr3 td7"><P class="p23 ft8">updates)</P></TD>
                <TD class="tr3 td8"><P class="p24 ft17">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr1 td3"><P class="p24 ft15">&nbsp;</P></TD>
                <TD class="tr1 td4"><P class="p24 ft15">&nbsp;</P></TD>
                <TD class="tr1 td5"><P class="p24 ft15">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr2 td6"
                  ><P class="p21 ft16">Content Data related to</P></TD
                >
                <TD class="tr2 td7"
                  ><P class="p22 ft16">We use these Data:</P></TD
                >
                <TD class="tr2 td8"
                  ><P class="p23 ft8">-A necessity to perform</P></TD
                >
              </TR>
              <TR>
                <TD class="tr3 td6"><P class="p22 ft16">the Services:</P></TD>
                <TD class="tr3 td7"
                  ><P class="p25 ft18">-To provide you with an</P></TD
                >
                <TD class="tr3 td8"><P class="p23 ft8">the contract</P></TD>
              </TR>
              <TR>
                <TD class="tr3 td6"
                  ><P class="p21 ft8">-Correspondence or chat</P></TD
                >
                <TD class="tr3 td8"><P class="p23 ft8">optimal service</P></TD>
                <TD class="tr3 td8"><P class="p23 ft8">-Consent</P></TD>
              </TR>
              <TR>
                <TD class="tr3 td6"><P class="p21 ft8">messages</P></TD>
                <TD class="tr3 td7"
                  ><P class="p22 ft8">-To execute our agreement</P></TD
                >
                <TD class="tr3 td8"><P class="p23 ft8">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr3 td6"
                  ><P class="p21 ft8">-Your questions about</P></TD
                >
                <TD class="tr3 td8"><P class="p24 ft17">&nbsp;</P></TD>
                <TD class="tr3 td8"><P class="p24 ft17">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr3 td6"><P class="p21 ft8">our Services</P></TD>
                <TD class="tr3 td7"><P class="p24 ft17">&nbsp;</P></TD>
                <TD class="tr3 td8"><P class="p24 ft17">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr3 td6"><P class="p21 ft8">-Location Data</P></TD>
                <TD class="tr3 td7"><P class="p24 ft17">&nbsp;</P></TD>
                <TD class="tr3 td8"><P class="p24 ft17">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr3 td6"><P class="p21 ft8">-IP address</P></TD>
                <TD class="tr3 td7"><P class="p24 ft17">&nbsp;</P></TD>
                <TD class="tr3 td8"><P class="p24 ft17">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr1 td3"><P class="p24 ft15">&nbsp;</P></TD>
                <TD class="tr1 td4"><P class="p24 ft15">&nbsp;</P></TD>
                <TD class="tr1 td5"><P class="p24 ft15">&nbsp;</P></TD>
              </TR>
              <TR>
                <TD class="tr2 td6"><P class="p21 ft16">Other Data:</P></TD>
                <TD class="tr2 td7"
                  ><P class="p22 ft16">We use these Data:</P></TD
                >
                <TD class="tr2 td8"
                  ><P class="p23 ft8">-A necessity to perform</P></TD
                >
              </TR>
              <TR>
                <TD class="tr3 td6"><P class="p24 ft17">&nbsp;</P></TD>
                <TD class="tr3 td7"
                  ><P class="p22 ft8">-To optimise our Services</P></TD
                >
                <TD class="tr3 td8"><P class="p23 ft8">the contract</P></TD>
              </TR>
              <TR>
                <TD class="tr3 td6"><P class="p24 ft17">&nbsp;</P></TD>
                <TD class="tr3 td7"><P class="p24 ft17">&nbsp;</P></TD>
                <TD class="tr3 td8"><P class="p23 ft8">-Consent</P></TD>
              </TR>
              <TR>
                <TD class="tr5 td3"><P class="p24 ft20">&nbsp;</P></TD>
                <TD class="tr5 td4"><P class="p24 ft20">&nbsp;</P></TD>
                <TD class="tr5 td5"><P class="p24 ft20">&nbsp;</P></TD>
              </TR>
            </TABLE>
          </div>
          <P class="p26 ft7">How do we receive your Personal Data?</P>
          <P class="p27 ft21">Personal data we receive from you:</P>
          <P class="p27 ft8"
            >We receive Personal Data directly from you when you log in via our
            website.</P
          >
          <P class="p28 ft7"
            >Are you obliged to share your Personal Data with us?</P
          >
          <P class="p29 ft22"
            >In some cases, the processing of your Personal Data is necessary.
            This is relevant, for example, when we have to process your Personal
            Data in order to oblige to a contract with you or to provide a
            service to you. Without your Personal Data, we cannot provide our
            Service to you.</P
          >
          <P class="p30 ft7">Automated individual decision-making</P>
          <P class="p27 ft8"
            >We do not use automated individual decision-making.</P
          >
          <P class="p31 ft7">How do we secure your Personal Data?</P>
          <P class="p32 ft6"
            >We do our utmost to protect your Personal Data from being lost,
            destroyed, abused, altered or spread by unauthorized parties. For
            this reason, persons who have nothing to do with your Personal Data,
            do not have access to them. We ensure this by taking the following
            measures:</P
          >
          <P class="p14 ft12"
            ><Icon x-small iconClass="pb-2" color="black">{{
              svgMovieOpen
            }}</Icon
            ><SPAN class="ft11"
              >Encryption (encoding) of digital files containing Personal
              Data;</SPAN
            ></P
          >
          <P class="p14 ft12"
            ><Icon x-small iconClass="pb-2" color="black">{{
              svgMovieOpen
            }}</Icon
            ><SPAN class="ft11"
              >Secure network connections with Secure Socket Layer (SSL), or a
              comparable technology;</SPAN
            ></P
          >
          <P class="p14 ft12"
            ><Icon x-small iconClass="pb-2" color="black">{{
              svgMovieOpen
            }}</Icon
            ><SPAN class="ft11"
              >The access to the Personal Data is strictly limited to the
              employees on a ‘need to know’ basis;</SPAN
            ></P
          >
          <P class="p14 ft12"
            ><Icon x-small iconClass="pb-2" color="black">{{
              svgMovieOpen
            }}</Icon
            ><SPAN class="ft11"
              >The access to the Personal Data is secured by </SPAN
            >two-step authentication.</P
          >
          <P class="p36 ft7">How long do we store your Personal Data?</P>
          <P class="p37 ft6"
            >We shall not store your Personal Data longer than the period in
            which we need them for the aforementioned purposes. We delete the
            Personal Data after we no longer need them for the purpose we
            process them for.</P
          >
          <P class="p30 ft7">With whom do we share your Personal Data?</P>
          <P class="p27 ft16">Processors</P>
          <P class="p39 ft23"
            >We may share your Personal Data with data ‘processors’ within the
            meaning of the Relevant Legislation. We conclude a Data processing
            agreement with these parties, which entails that they shall process
            your Data carefully and that they shall only receive the Personal
            Data they need to provide their service. These parties shall only
            use your Personal Data in accordance with our instructions and not
            for their own purposes. We only share your Personal Data with the
            following categories of processors: Google Cloud Platform (GCP) and
            Stripe (payment processor). They perform the following tasks for us:
            take care of the storage of personal data and the execution of
            payment services.</P
          >
          <P class="p40 ft4"
            >If we have a legal obligation to share your Personal Data, we will
            do so. This is the case, for example, if a public authority legally
            requires us to share your Personal Data.</P
          >
          <P class="p6 ft7">Transfer</P>
          <P class="p41 ft6"
            >We shall only process your Personal Data within the European Union.
            We shall only process your data outside the European Union if that
            country offers an appropriate level of protection for your Personal
            Data.</P
          >
          <P class="p42 ft4"
            >We shall never transfer your Personal Data to other countries or to
            other parties than those mentioned above.</P
          >
          <P class="p6 ft7">Links</P>
          <P class="p43 ft6"
            >Our website and our App may contain links to other websites. We are
            not responsible for the content or the privacy protection on these
            websites. Therefore, we advise you to always read the Privacy Policy
            of those websites.</P
          >
          <P class="p30 ft7">Cookies</P>
          <P class="p44 ft6"
            >A cookie is a small text file that can be sent via the server of a
            website to the browser. The browser saves this file to your
            computer. Your computer is tagged with a unique number, which
            enables our site to recognize that computer in the future.</P
          >
          <P class="p45 ft6"
            >We use cookies to improve the user experience on our Website.
            Moreover, cookies ensure that the Website works faster, that you can
            visit our Website safely and that we can track and solve errors on
            our Website.</P
          >
          <P class="p46 ft6"
            >You can always delete or disable cookies yourself via the browser
            settings. No more cookies will be stored when you visit our Website.
            However, please note that without cookies, our Website may not
            function as well as it should. For more information you can read our
            cookie statement: www.visionbook.com/cookiestatement.</P
          >
          <P class="p47 ft7">Modifications to the Privacy Policy</P>
          <P class="p49 ft4"
            >We may modify this Privacy Policy. If we substantially modify the
            Privacy Policy, we shall place a notification on our Website and in
            our App together with the new Privacy Policy. We shall notify
            registered users in case of a substantial modification. If you are
            not a registered user, we advise you to consult the Website and this
            Policy regularly.</P
          >
          <P class="p10 ft7">Your rights</P>
          <P class="p50 ft8">You have the following rights:</P>
          <P class="p51 ft26"
            ><Icon x-small iconClass="pb-1" color="black">{{
              svgMovieOpen
            }}</Icon
            ><SPAN class="ft10"
              >You can request access to your Personal Data;</SPAN
            ></P
          >
          <P class="p51 ft26"
            ><Icon x-small iconClass="pb-1" color="black">{{
              svgMovieOpen
            }}</Icon
            ><SPAN class="ft25"
              >You can request us to correct, limit or delete your Personal
              Data. In the event of fraud, non payment or other wrongful acts,
              we can store some of your Personal Data in a register or on a
              blacklist;</SPAN
            ></P
          >
          <P class="p52 ft12"
            ><Icon x-small iconClass="pb-1" color="black">{{
              svgMovieOpen
            }}</Icon
            ><SPAN class="ft11"
              >You can request a copy of your Personal Data. We can provide this
              copy to third parties at your request, so you do not have to do so
              yourself;</SPAN
            ></P
          >
          <P class="p53 ft14"
            ><Icon x-small iconClass="pb-1" color="black">{{
              svgMovieOpen
            }}</Icon
            ><SPAN class="ft27"
              >You can object to the processing of your Personal Data;</SPAN
            ></P
          >
          <P class="p53 ft14"
            ><Icon x-small iconClass="pb-1" color="black">{{
              svgMovieOpen
            }}</Icon
            ><SPAN class="ft27"
              >You can file a complaint at the Dutch Data Protection Authority
              (Autoriteit Persoonsgegevens) if you are of the opinion that we
              wrongfully process your data;</SPAN
            ></P
          >
          <P class="p54 ft12"
            ><Icon x-small iconClass="pb-1" color="black">{{
              svgMovieOpen
            }}</Icon
            ><SPAN class="ft11"
              >You can always withdraw your permission to process your Personal
              Data. From the moment of your withdrawal, we cannot process your
              Personal Data anymore.</SPAN
            ></P
          >
          <P class="p55 ft7">Contact</P>
          <P class="p56 ft2"
            >In the event that you wish to exercise these rights, or in the
            event of other questions or remarks regarding our Privacy Policy,
            you can contact us via the following contact details.</P
          >
          <P class="p57 ft8">The Scalable Factory B.V.</P>
          <P class="p58 ft8">Willem van Noortstraat 76</P>
          <P class="p59 ft23">3514 GG Utrecht, The Netherlands</P>
          <P class="p59 ft23">Chambre of commerce: 78141788</P>
          <P class="p59 ft23"> info@visionbook.com</P>
        </DIV>
      </v-col>
    </v-row>
  </v-container>
</template>
<!-- eslint-enable -->

<script>
import { mdiMovieOpen } from "@mdi/js";

export default {
  name: "Privacy",
  data() {
    return {
      svgMovieOpen: mdiMovieOpen
    };
  }
};
</script>

<style scoped>
#page_1 {
  position: relative;
  overflow: hidden;
  padding-bottom: 64px;
  border: none;
  max-width: 699px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.ft0 {
  font-size: 27px;
  line-height: 32px;
}
.ft1 {
  font-size: 14px;
  font-weight: bold;
  line-height: 23px;
}
.ft2 {
  font-size: 14px;
  line-height: 23px;
}
.ft3 {
  font-size: 14px;
  font-weight: bold;
  line-height: 23px;
}
.ft4 {
  font-size: 14px;
  line-height: 23px;
}
.ft5 {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
}
.ft6 {
  font-size: 14px;
  line-height: 24px;
}
.ft7 {
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
}
.ft8 {
  font-size: 14px;
  line-height: 16px;
}
.ft10 {
  font-size: 14px;
  margin-left: 4px;
  line-height: 16px;
}
.ft11 {
  font-size: 14px;
  margin-left: 5px;
  line-height: 20px;
}
.ft12 {
  font-size: 14px;
  line-height: 20px;
}
.ft13 {
  font-size: 14px;
  margin-left: 4px;
  line-height: 21px;
}
.ft14 {
  font-size: 14px;
  line-height: 21px;
}
.ft15 {
  font-size: 1px;
  line-height: 6px;
}
.ft16 {
  font-style: italic;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
}
.ft17 {
  font-size: 1px;
  line-height: 1px;
}
.ft18 {
  font-size: 14px;
  line-height: 16px;
}
.ft19 {
  font-size: 1px;
  line-height: 7px;
}
.ft20 {
  font-size: 1px;
  line-height: 14px;
}
.ft21 {
  font-size: 14px;
  text-decoration: underline;
  line-height: 16px;
}
.ft22 {
  font-size: 14px;
  line-height: 24px;
}
.ft23 {
  font-size: 14px;
  line-height: 24px;
}
.ft25 {
  font-size: 14px;
  margin-left: 5px;
  line-height: 21px;
}
.ft26 {
  font-size: 14px;
  line-height: 21px;
}
.ft27 {
  font-size: 14px;
  margin-left: 5px;
  line-height: 21px;
}

.p0 {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p1 {
  text-align: left;
  margin-top: 47px;
  margin-bottom: 0px;
  text-indent: 1px;
}
.p2 {
  text-align: left;
  margin-top: 23px;
  margin-bottom: 0px;
  text-indent: 1px;
}
.p3 {
  text-align: left;
  padding-left: 1px;
  margin-top: 16px;
  margin-bottom: 0px;
}
.p4 {
  text-align: left;
  margin-top: 27px;
  margin-bottom: 0px;
}
.p5 {
  text-align: left;
  padding-left: 1px;
  margin-top: 9px;
  margin-bottom: 0px;
}
.p6 {
  text-align: left;
  padding-left: 1px;
  margin-top: 21px;
  margin-bottom: 0px;
}
.p7 {
  text-align: left;
  padding-left: 1px;
  margin-top: 9px;
  margin-bottom: 0px;
}
.p8 {
  text-align: left;
  padding-left: 1px;
  margin-top: 34px;
  margin-bottom: 0px;
}
.p9 {
  text-align: left;
  padding-left: 1px;
  margin-top: 9px;
  margin-bottom: 0px;
}
.p10 {
  text-align: left;
  margin-top: 21px;
  margin-bottom: 0px;
}
.p11 {
  text-align: left;
  padding-left: 1px;
  margin-top: 26px;
  margin-bottom: 0px;
}
.p12 {
  text-align: left;
  margin-top: 4px;
  margin-bottom: 0px;
}
.p13 {
  text-align: left;
  margin-top: 6px;
  margin-bottom: 0px;
}
.p14 {
  text-align: left;
  padding-left: 28px;
  margin-top: 8px;
  margin-bottom: 0px;
  text-indent: -28px;
}
.p15 {
  text-align: left;
  margin-top: 8px;
  margin-bottom: 0px;
}
.p16 {
  text-align: left;
  margin-top: 5px;
  margin-bottom: 0px;
}
.p17 {
  text-align: left;
  padding-left: 1px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p18 {
  text-align: left;
  padding-left: 2px;
  margin-top: 22px;
  margin-bottom: 0px;
}
.p19 {
  text-align: left;
  padding-left: 2px;
  margin-top: 21px;
  margin-bottom: 0px;
}
.p20 {
  text-align: left;
  padding-left: 2px;
  margin-top: 21px;
  margin-bottom: 0px;
}
.p21 {
  text-align: left;
  padding-left: 13px;
  margin-top: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
}
.p22 {
  text-align: left;
  padding-left: 12px;
  margin-top: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
}
.p23 {
  text-align: left;
  padding-left: 11px;
  margin-top: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
}
.p24 {
  text-align: left;
  margin-top: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
}
.p25 {
  text-align: left;
  padding-left: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
}
.p26 {
  text-align: left;
  padding-left: 2px;
  margin-top: 40px;
  margin-bottom: 0px;
}
.p27 {
  text-align: left;
  padding-left: 2px;
  margin-top: 9px;
  margin-bottom: 0px;
}
.p28 {
  text-align: left;
  padding-left: 1px;
  margin-top: 40px;
  margin-bottom: 0px;
}
.p29 {
  text-align: left;
  padding-left: 1px;
  margin-top: 9px;
  margin-bottom: 0px;
  text-indent: 1px;
}
.p30 {
  text-align: left;
  padding-left: 1px;
  margin-top: 20px;
  margin-bottom: 0px;
}
.p31 {
  text-align: left;
  padding-left: 2px;
  margin-top: 25px;
  margin-bottom: 0px;
}
.p32 {
  text-align: left;
  padding-left: 1px;
  margin-top: 9px;
  margin-bottom: 0px;
  text-indent: 1px;
}
.p33 {
  text-align: left;
  padding-left: 2px;
  margin-top: 11px;
  margin-bottom: 0px;
}
.p34 {
  text-align: left;
  padding-left: 1px;
  margin-top: 8px;
  margin-bottom: 0px;
}
.p35 {
  text-align: left;
  padding-left: 1px;
  margin-top: 7px;
  margin-bottom: 0px;
}
.p36 {
  text-align: left;
  padding-left: 2px;
  margin-top: 22px;
  margin-bottom: 0px;
}
.p37 {
  text-align: left;
  padding-left: 2px;
  margin-top: 9px;
  margin-bottom: 0px;
}
.p38 {
  text-align: left;
  padding-left: 2px;
  margin-top: 1px;
  margin-bottom: 0px;
}
.p39 {
  text-align: left;
  padding-left: 1px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p40 {
  text-align: left;
  padding-left: 1px;
  margin-bottom: 0px;
}
.p41 {
  text-align: left;
  padding-left: 1px;
  margin-top: 9px;
  margin-bottom: 0px;
}
.p42 {
  text-align: left;
  padding-left: 1px;
  margin-top: 20px;
  margin-bottom: 0px;
}
.p43 {
  text-align: left;
  padding-left: 1px;
  margin-top: 9px;
  margin-bottom: 0px;
}
.p44 {
  text-align: left;
  padding-left: 1px;
  margin-top: 9px;
  margin-bottom: 0px;
}
.p45 {
  text-align: left;
  padding-left: 1px;
  margin-top: 20px;
  margin-bottom: 0px;
}
.p46 {
  text-align: left;
  margin-top: 18px;
  margin-bottom: 0px;
}
.p47 {
  text-align: left;
  padding-left: 1px;
  margin-top: 24px;
  margin-bottom: 0px;
}
.p48 {
  text-align: left;
  padding-left: 1px;
  margin-top: 1px;
  margin-bottom: 0px;
}
.p49 {
  text-align: left;
  padding-left: 1px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p50 {
  text-align: left;
  margin-top: 9px;
  margin-bottom: 0px;
}
.p51 {
  text-align: left;
  padding-left: 27px;
  margin-top: 6px;
  margin-bottom: 0px;
  text-indent: -27px;
}
.p52 {
  text-align: left;
  padding-left: 28px;
  margin-top: 6px;
  margin-bottom: 0px;
  text-indent: -28px;
}
.p53 {
  text-align: left;
  padding-left: 27px;
  margin-top: 5px;
  margin-bottom: 0px;
  text-indent: -27px;
}
.p54 {
  text-align: left;
  padding-left: 27px;
  margin-top: 7px;
  margin-bottom: 0px;
  text-indent: -27px;
}
.p55 {
  text-align: left;
  padding-left: 1px;
  margin-top: 23px;
  margin-bottom: 0px;
}
.p56 {
  text-align: left;
  padding-left: 1px;
  margin-top: 9px;
  margin-bottom: 0px;
}
.p57 {
  text-align: left;
  padding-left: 1px;
  margin-top: 45px;
  margin-bottom: 0px;
}
.p58 {
  text-align: left;
  padding-left: 1px;
  margin-top: 11px;
  margin-bottom: 0px;
}
.p59 {
  text-align: left;
  padding-left: 1px;
  margin-top: 6px;
  margin-bottom: 0px;
}

.td0 {
  border-left: #000000 1px solid;
  border-right: #000000 1px solid;
  border-top: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 200px;
  vertical-align: bottom;
}
.td1 {
  border-right: #000000 1px solid;
  border-top: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 201px;
  vertical-align: bottom;
}
.td2 {
  border-right: #000000 1px solid;
  border-top: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 200px;
  vertical-align: bottom;
}
.td3 {
  border-left: #000000 1px solid;
  border-right: #000000 1px solid;
  border-bottom: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 200px;
  vertical-align: bottom;
}
.td4 {
  border-right: #000000 1px solid;
  border-bottom: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 201px;
  vertical-align: bottom;
}
.td5 {
  border-right: #000000 1px solid;
  border-bottom: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 200px;
  vertical-align: bottom;
}
.td6 {
  border-left: #000000 1px solid;
  border-right: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 200px;
  vertical-align: bottom;
}
.td7 {
  border-right: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 201px;
  vertical-align: bottom;
}
.td8 {
  border-right: #000000 1px solid;
  padding: 0px;
  margin: 0px;
  width: 200px;
  vertical-align: bottom;
}

.tr0 {
  height: 24px;
}
.tr1 {
  height: 6px;
}
.tr2 {
  height: 25px;
}
.tr3 {
  height: 17px;
}
.tr4 {
  height: 7px;
}
.tr5 {
  height: 14px;
}

.t0 {
  max-width: 604px;
  margin-top: 4px;
  font-size: 14px;
  display: inline-table;
  border-spacing: 0;
  border-collapse: separate;
}
.t1 {
  max-width: 604px;
  font-size: 14px;
}
</style>
